import { createTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import '../components/typography.css';

const primary = {
  light: '#000000',
  main: '#00a9e1',
  dark: '#0080b7',
  contrastText: '#fff',
};

const secondary = {
  light: '#000000',
  main: '#0080b7',
  dark: '#000000',
  contrastText: '#fff',
};

export const theme = {
  palette: {
    type: 'light',
    primary,
    secondary,
    success: {
      light: green[300],
      main: green[500],
      dark: green[700],
    },
    danger: {
      light: red[300],
      main: red[500],
      dark: red[700],
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: secondary.light,
    },
  },
  typography: {
    fontSize: 16,
    textTransform: 'none',
    h1: {
      fontFamily: 'Tommy',
    },
    h2: {
      fontFamily: 'Tommy',
    },
    h3: {
      fontFamily: 'Tommy',
    },
    h4: {
      fontFamily: 'Tommy',
    },
    h5: {
      fontFamily: 'Tommy',
    },
    h6: {
      fontFamily: 'Tommy',
    },
    subtitle1: {
      fontFamily: 'Gotham',
    },
    subtitle2: {
      fontFamily: 'Gotham',
    },
    caption: {
      fontFamily: 'Gotham',
    },
    button: {
      fontFamily: 'Gotham',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '25px',
        paddingLeft: '24px',
        paddingRight: '24px',
        fontWeight: 600,
      },
      contrastText: '#000000',
    },
  },
};

const muiTheme = createTheme(theme);
export default muiTheme;

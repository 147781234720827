import Amplify from 'aws-amplify';

import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import 'typeface-barlow-condensed';
import 'typeface-montserrat';
import './src/styles/all.scss';
// eslint-disable-next-line import/no-named-as-default
import theme from './src/themes/Material-UI';

import config from './src/config';

const { cognito } = config;

Amplify.configure({
  Auth: cognito,
});

function wrapRootElement({ element }) {
  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {element}
    </ThemeProvider>
  );
}

export default wrapRootElement;

const config = {
  APP_URL: process.env.GATSBY_APP_URL,
  API: process.env.GATSBY_APP_API || 'https://api.dev.trustalice.com',
  stage: process.env.GATSBY_APP_STAGE || 'dev',
  region: process.env.GATSBY_APP_REGION,
};

export default {
  ...config,
};
